import React, { memo } from 'react'
import { Skeleton } from 'antd'
import { times } from 'lodash'

const _AvatarGroupSkeleton = ({ amount = 4 }) => {
  const avatars = times(amount, i => <Skeleton.Avatar key={i} active />)

  return <div className="skeleton-group">{avatars}</div>
}

export const AvatarGroupSkeleton = memo(_AvatarGroupSkeleton)
