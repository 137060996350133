import classNames from 'classnames'
import { useMergeState } from 'common/hooks/useMergeState'
import React, { useCallback, useMemo, useRef } from 'react'

import { ArrowButton } from '@/components/elements/buttons/arrowButton'

const CARD_SCROLL_SPEED = 10
const CARD_SCROLL_INTERVAL = 20
const CARD_SCROLL_THRESHOLD = 400

const isScrollStart = target => target.scrollLeft > 0
const isScrollEnd = target => target.scrollLeft < target.scrollWidth - target.clientWidth

/* In order for this component to work,
the child has be horizontally scrollable */
const ScrollDrag = ({
  children,
  enabled = true,
  hideScrollbarDesktop = false,
  hideScrollbarMobile = false,
  arrows = false,
  gradient = false,
  gap = false
}) => {
  const ref = useRef(null)
  const [drag, setDrag] = useMergeState({
    isScrolling: false,
    clientX: 0,
    scrollX: 0,
    interval: null
  })

  const scrollClassName = useMemo(
    () =>
      classNames(
        {
          'hide-scrollbar-desktop': hideScrollbarDesktop
        },
        {
          'hide-scrollbar-mobile': hideScrollbarMobile
        },
        {
          'gradient-mask': gradient
        },
        {
          'include-gap': gap
        },
        'horizontal-drag-scroll'
      ),
    [hideScrollbarDesktop, hideScrollbarMobile]
  )

  const onMouseDown = useCallback(
    e => {
      setDrag({ isScrolling: true, clientX: e.clientX })
    },
    [setDrag]
  )

  const onMouseLeave = useCallback(
    e => {
      clearInterval(drag.interval)
      setDrag({ isScrolling: false, clientX: e.clientX, interval: null })
    },
    [setDrag]
  )

  const onMouseUp = useCallback(() => {
    clearInterval(drag.interval)
    setDrag({ isScrolling: false, interval: null })
  }, [setDrag])

  const onMouseMove = useCallback(
    e => {
      const { clientX, scrollX, isScrolling } = drag
      const prevScrollX = ref.current.scrollLeft

      if (isScrolling) {
        const scrollLeft = -(-scrollX + e.clientX - clientX)
        ref.current.scrollLeft = scrollLeft
        if (ref.current.scrollLeft === prevScrollX && prevScrollX >= 0) return

        setDrag(prev => ({
          ...prev,
          clientX: e.clientX,
          scrollX: scrollLeft
        }))
      }
    },
    [drag, setDrag]
  )

  const onScroll = useCallback(
    e => {
      if (drag.isScrolling) return
      setDrag({
        scrollX: e.target.scrollLeft
      })
    },
    [drag, setDrag]
  )

  const onMouseDownLeft = useCallback(() => {
    setDrag({
      interval: setInterval(() => {
        ref.current.scrollLeft -= CARD_SCROLL_SPEED
      }, CARD_SCROLL_INTERVAL)
    })
  }, [setDrag])

  const onMouseDownRight = useCallback(() => {
    setDrag({
      interval: setInterval(() => {
        ref.current.scrollLeft += CARD_SCROLL_SPEED
      }, CARD_SCROLL_INTERVAL)
    })
  }, [setDrag])

  const onClickLeft = useCallback(() => {
    let counter = 0
    let interval = setInterval(() => {
      ref.current.scrollLeft -= CARD_SCROLL_SPEED
      counter += CARD_SCROLL_SPEED
      counter >= CARD_SCROLL_THRESHOLD && clearInterval(interval)
    }, CARD_SCROLL_INTERVAL)
    setDrag({
      interval
    })
  }, [setDrag])

  const onClickRight = useCallback(() => {
    let counter = 0
    let interval = setInterval(() => {
      ref.current.scrollLeft += CARD_SCROLL_SPEED
      counter += CARD_SCROLL_SPEED
      counter >= CARD_SCROLL_THRESHOLD && clearInterval(interval)
    }, CARD_SCROLL_INTERVAL)
    setDrag({
      interval
    })
  }, [setDrag])

  const scrollArrows = ref.current && (
    <>
      {isScrollStart(ref.current) && (
        <ArrowButton
          className="scroll-left-button"
          onMouseDown={onMouseDownLeft}
          onClick={onClickLeft}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseUp}
          left
        />
      )}
      {isScrollEnd(ref.current) && (
        <ArrowButton
          className="scroll-right-button"
          onMouseDown={onMouseDownRight}
          onClick={onClickRight}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseUp}
        />
      )}
    </>
  )

  if (!enabled) return children

  return (
    <>
      {arrows && scrollArrows}

      <div
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onScroll={onScroll}
        className={scrollClassName}
        role="button"
        tabIndex={-1}
      >
        {children}
      </div>
    </>
  )
}

export default ScrollDrag
