const Month_D_Yr = date =>
  date?.toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })

const Month_Yr = date =>
  date.toLocaleDateString(undefined, {
    month: 'short',
    year: 'numeric'
  })

const Interval = ({ start, end }) => {
  return `${Month_Yr(start)} - ${end ? Month_Yr(end) : 'Present'}`
}

export const dateFormat = { Month_D_Yr, Month_Yr, Interval }

export const timeStrToSeconds = (time) => {
  const timeSplitted = time.split(':');
  const hours = parseInt(timeSplitted[0]);
  const minutes = parseInt(timeSplitted[1]);
  const secondsSplitted = timeSplitted[2];
  const seconds = secondsSplitted.search('.') ? parseFloat(timeSplitted[2]) : parseInt(secondsSplitted);
  return (hours * 3600) + (minutes * 60) + seconds;
}

export const formatTimeStr = (time) => {
  const timeSplitted = time.split(':');
  const hours = parseInt(timeSplitted[0]);
  const minutes = timeSplitted[1];
  const seconds = (timeSplitted[2].slice(0, 2));

  // DOC: Parse the time following:
  // - Always display the seconds and minutes
  // - Don't display hours value if it is equal to 0
  // - If not displaying hours parse minutes to Integer to remove 0 if minutes < 10
  return `${hours === 0 ?  parseInt(minutes) : `${hours}:${minutes}`}:${seconds}`;
}
