import React, { memo } from 'react'
import { Skeleton } from 'antd'
import { AvatarGroupSkeleton } from './avatarGroupSkeleton'

const _ContentCardSkeleton = ({ className, date = false }) => (
  <div className={className}>
    <Skeleton.Node
      children=""
      active
      className="content-card-cover"
      style={{
        width: '100%',
        height: '100%'
      }}
    />
    <Skeleton.Node
      children=""
      active
      style={{
        width: '200px',
        height: '20px',
        visibility: date ? 'visible' : 'hidden'
      }}
    />
    <Skeleton.Node
      children=""
      active
      style={{
        width: '150px',
        height: '30px'
      }}
    />
    <Skeleton
      title={false}
      paragraph={{ rows: 2 }}
      active
      className="skeleton-paragraph"
    />
    <AvatarGroupSkeleton />
  </div>
)

export const ContentCardSkeleton = memo(_ContentCardSkeleton)
