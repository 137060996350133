import classNames from 'classnames'
import React, { memo } from 'react'

import { ContentCardSkeleton } from '../skeletons/contentCardSkeleton'
import { isEmpty } from 'lodash'

const _ContentCard = ({
  cover,
  bottom,
  date,
  title,
  subtitle,
  description,
  button,
  isShimmer = false,
  className,
  clamp = true,
  isGalleryCard = false
}) => {
  const containerClass = classNames(
    { [`${className}`]: className },
    {
      'gallery-card': isGalleryCard
    },
    'content-card-container'
  )

  if (isShimmer) {
    return <ContentCardSkeleton className={containerClass} date={date} />
  }

  const titleElement =
    typeof title === 'string' ? <h2 className="content-card-title">{title}</h2> : title

  return (
    <div className={containerClass}>
      <div className="content-card-cover">{cover}</div>
      <div className="content-card-date">{date}</div>
      {titleElement}
      {subtitle && <h3 className="content-card-subtitle">{subtitle}</h3>}
      {description && (
        <p className={`content-card-description ${clamp && 'clamp-description'}`}>
          {description}
        </p>
      )}
      <div className="content-card-bottom">{bottom}</div>
      {button}
    </div>
  )
}

export const ContentCard = memo(_ContentCard)
