import classNames from 'classnames'

const Card = ({ children, placeholder = "Couldn't find any result", className }) => {
  const classes = classNames('card-wrapper', { className })
  return (
    <div className={classes}>
      {children ?? <div className="card-message">{placeholder}</div>}
    </div>
  )
}

export default Card
