import Image from 'next/image'
import React, { memo } from 'react'

const _ArrowButton = ({
  onClick,
  onMouseDown,
  onMouseUp,
  onMouseLeave,
  className,
  left = false
}) => (
  <button
    className={`arrow-button ${left && 'left-arrow-button'} ${className} unselectable`}
    onClick={onClick}
    onMouseDown={onMouseDown}
    onMouseUp={onMouseUp}
    onMouseLeave={onMouseLeave}
  >
    <Image
      src="/icons/arrow-right.svg"
      alt="arrow button"
      layout="fill"
      objectFit="contain"
    />
  </button>
)

export const ArrowButton = memo(_ArrowButton)
